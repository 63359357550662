@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face { 
    font-family: "gotham-bold"; 
    src: url("gothamrounded-bold.ttf"); 
} 
@font-face { 
    font-family: "gotham-medium"; 
    src: url("GothamRounded-Medium.ttf"); 
}
