<template>
  <div class="bg-gray-50">
    <div class="">
      <h2 class="sr-only">Checkout</h2>

      <div class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div>
          <div>
            <h2 class="text-lg font-medium text-gray-900">Contact information</h2>

            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <div>
                <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                <div class="mt-1">
                  <input type="text" id="first-name" name="first-name" autocomplete="given-name" v-model="formData.firstName" @blur="handleFirstNameBlur" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="firstName-error">
                  <p v-if="error.$property === 'firstName'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>

              <div>
                <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                <div class="mt-1">
                  <input type="text" id="last-name" name="last-name" autocomplete="family-name" v-model="formData.lastName" @blur="handleLastNameBlur" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="lastName-error">
                  <p v-if="error.$property === 'lastName'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
              <div class="mt-1">
                <input type="email" id="email-address" name="email-address" autocomplete="email" v-model="formData.email" @blur="handleEmailBlur" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
              </div>
              <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="email-error">
                <p v-if="error.$property === 'email'">
                  {{ error.$message }}
                </p>
              </div>
            </div>

            <div class="sm:col-span-2 mt-4">
              <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
              <div class="mt-1">
                <input type="text" name="phone" id="phone" autocomplete="tel" v-model="formData.phoneNumber" @blur="handlePhoneBlur" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
              </div>
              <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="phoneNumber-error">
                <p v-if="error.$property === 'phoneNumber'">
                  {{ error.$message }}
                </p>
              </div>
            </div>

            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <div class="">
                <label for="activationDateOption" class="block text-sm font-medium text-gray-700">Service activation date</label>
                <div class="mt-1">
                  <select id="activationDateOption" v-model="formData.formSelectionOptions.pickActivationDate" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" @change="handleActivationDate">
                    <option v-bind:value="false">ASAP</option>
                    <option v-bind:value="true">Pick a Date</option>
                  </select>
                </div>
              </div>

              <div v-show="formData.formSelectionOptions.pickActivationDate" class="">
                <label for="activationDate" class="block text-sm font-medium text-gray-700">Confirm service activation date</label>
                <div class="mt-1">
                  <input type="date" :min="tomorrow" name="activationDate" id="activationDate" v-model="formData.activationDate" @blur="v$.activationDate.$touch" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="activationDate-error">
                  <p v-if="error.$property === 'activationDate'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>
            </div>

            <div class="mt-6 flex space-x-2">
              <div class="flex items-center h-5">
                <input @click="handleClickBusinessAccount" id="same-as-shipping" name="same-as-shipping" type="checkbox" v-model="formData.formSelectionOptions.businessAccount" class="h-4 w-4 border-gray-200 rounded text-brand-accent focus:ring-brand-accent" />
              </div>
              <label for="same-as-shipping" class="text-sm font-medium text-gray-900">Is this a business account?</label>
            </div>

            <div v-if="formData.formSelectionOptions.businessAccount" class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <div>
                <label for="company-name" class="block text-sm font-medium text-gray-700">Company name</label>
                <div class="mt-1">
                  <input type="text" id="company-name" name="company-name" autocomplete="organization" v-model="formData.companyName" @blur="v$.companyName.$touch" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="companyName-error">
                  <p v-if="error.$property === 'companyName'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>

              <div>
                <label for="abn-number" class="block text-sm font-medium text-gray-700">ABN Number</label>
                <div class="mt-1">
                  <input type="text" id="abn-number" name="abn-number" v-model="formData.abnNumber" @blur="v$.abnNumber.$touch" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="abnNumber-error">
                  <p v-if="error.$property === 'abnNumber'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Payment -->
          <div class="mt-10 border-t border-gray-200 pt-10">
            <h2 class="text-lg font-medium text-gray-900">Payment Method</h2>

            <div v-if="!directDebitFormFields" class="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
              <div class="col-span-4">
                <label for="card-number" class="block text-sm font-medium text-gray-700">Card number</label>
                <div class="mt-1">
                  <input type="text" id="card-number" name="card-number" autocomplete="cc-number" v-model="formData.ccNumber" @blur="v$.ccNumber.$touch" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="ccNumber-error">
                  <p v-if="error.$property === 'ccNumber'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>

              <div class="col-span-4">
                <label for="name-on-card" class="block text-sm font-medium text-gray-700">Name on card</label>
                <div class="mt-1">
                  <input type="text" id="name-on-card" name="name-on-card" autocomplete="cc-name" v-model="formData.ccName" @blur="v$.ccName.$touch" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="ccName-error">
                  <p v-if="error.$property === 'ccName'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>

              <div class="col-span-3">
                <label for="expiration-date" class="block text-sm font-medium text-gray-700">Expiration date (MM/YY)</label>
                <div class="mt-1">
                  <input type="text" name="expiration-date" id="expiration-date" autocomplete="cc-exp" v-model="formData.ccExpiry" @blur="v$.ccExpiry.$touch" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="ccExpiry-error">
                  <p v-if="error.$property === 'ccExpiry'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>

              <div>
                <label for="cvc" class="block text-sm font-medium text-gray-700">CVC</label>
                <div class="mt-1">
                  <input type="text" name="cvc" id="cvc" autocomplete="cc-csc" v-model="formData.ccCvc" @blur="v$.ccCvc.$touch" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="ccCvc-error">
                  <p v-if="error.$property === 'ccCvc'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>
            </div>

            <div v-if="directDebitFormFields" class="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
              <div class="col-span-3">
                <label for="account-number" class="block text-sm font-medium text-gray-700">Account Number</label>
                <div class="mt-1">
                  <input type="text" name="account-number" id="account-number" v-model="formData.ddAccountNumber" @blur="v$.ddAccountNumber.$touch" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="ddAccountNumber-error">
                  <p v-if="error.$property === 'ddAccountNumber'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>

              <div>
                <label for="bsb" class="block text-sm font-medium text-gray-700">BSB</label>
                <div class="mt-1">
                  <input type="text" name="bsb" id="bsb" v-model="formData.ddAccountBsb" @blur="v$.ddAccountBsb.$touch" class="block w-full border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
                </div>
                <div v-for="error of v$.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600" id="ddAccountBsb-error">
                  <p v-if="error.$property === 'ddAccountBsb'">
                    {{ error.$message }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10 lg:mt-0">

          <!-- Address Confirmation -->
          <h2 class="text-lg font-medium text-gray-900">Confirm Details</h2>

          <div class="">
            <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
              <div class="py-6 px-4 space-y-6 sm:px-6">
                <div class="flex justify-between items-center">
                  <div class="">
                    <div class="min-w-0 flex-1">
                        <p class="text-xs text-brand-secondary font-light">
                          Confirm your service address below.
                        </p>
                        <p class="mt-1 text-lg">
                          {{ service.address }}
                        </p>
                      </div>
                    </div>
                  <div class="text-base font-medium text-gray-900">
                    <Switch
                      @click="handleClickConfirmAddress"
                      v-model="formData.formSelectionOptions.confirmAddressToggle"
                      :class="[
                        (formData.formSelectionOptions.confirmAddressToggle ? 'bg-brand-accent' : 'bg-gray-200'),
                        ('relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'),
                        (confirmAddressError ? 'ring-2 ring-offset-2 ring-red-600' : 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-accent')
                        ]"
                      >
                        <span class="sr-only">Use setting</span>
                        <span :class="[formData.formSelectionOptions.confirmAddressToggle ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                          <span :class="[formData.formSelectionOptions.confirmAddressToggle ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                            <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                              <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </span>
                          <span :class="[formData.formSelectionOptions.confirmAddressToggle ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                            <svg class="h-3 w-3 text-brand-accent" fill="currentColor" viewBox="0 0 12 12">
                              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                            </svg>
                          </span>
                        </span>
                    </Switch>
                  </div>
                </div>
                <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                  <div class="text-sm font-light pr-6">
                    I have read, understood and accept the <a :href="`https://uniti-bpo-website-assets.assets.unitiinternet.com/${brandService.getName()}/terms-and-conditions.pdf`" target="_blank" class="text-blue-500 hover:text-blue-800 hover:underline transition duration-250">Terms and Conditions.</a>
                  </div>
                  <div class="text-base font-medium text-gray-900">
                    <Switch
                      @click="handleClickTermsAgreement"
                      v-model="formData.formSelectionOptions.termsAgreementToggle"
                      :class="[
                        (formData.formSelectionOptions.termsAgreementToggle ? 'bg-brand-accent' : 'bg-gray-200'),
                        ('relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'),
                        (termsAgreementError ? 'ring-2 ring-offset-2 ring-red-600' : 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-accent')
                        ]"
                      >
                        <span class="sr-only">Use setting</span>
                        <span :class="[formData.formSelectionOptions.termsAgreementToggle ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                          <span :class="[formData.formSelectionOptions.termsAgreementToggle ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                            <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                              <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </span>
                          <span :class="[formData.formSelectionOptions.termsAgreementToggle ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                            <svg class="h-3 w-3 text-brand-accent" fill="currentColor" viewBox="0 0 12 12">
                              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                            </svg>
                          </span>
                        </span>
                    </Switch>
                  </div>
                </div>
                <div v-if="voiceProduct" class="flex items-center justify-between border-t border-gray-200 pt-6">
                  <div class="text-sm font-light" @click="toggleExpandCsgWaiver()">
                    I have read, understood and accept the <span class="text-blue-500 hover:text-blue-800 hover:underline transition duration-250">CSG waiver.</span>

                    <p class="text-red-400" v-if="formData.formSelectionOptions.csgWaiverToggle && salesPerson">Ensure you have read the <a class="text-red-600" :href="$router.resolve({ name: 'csg-waiver-script' }).href" target="_blank">CSG Waiver Script</a></p>
                  </div>
                  <div class="text-base font-medium text-gray-900">
                    <Switch
                      @click="handleClickCsgWaiver"
                      v-model="formData.formSelectionOptions.csgWaiverToggle"
                      :class="[
                        (formData.formSelectionOptions.csgWaiverToggle ? 'bg-brand-accent' : 'bg-gray-200'),
                        ('relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'),
                        (csgWaiverError ? 'ring-2 ring-offset-2 ring-red-600' : 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-accent')
                        ]"
                      >
                        <span class="sr-only">Use setting</span>
                        <span :class="[formData.formSelectionOptions.csgWaiverToggle ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                          <span :class="[formData.formSelectionOptions.csgWaiverToggle ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                            <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                              <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </span>
                          <span :class="[formData.formSelectionOptions.csgWaiverToggle ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                            <svg class="h-3 w-3 text-brand-accent" fill="currentColor" viewBox="0 0 12 12">
                              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                            </svg>
                          </span>
                        </span>
                    </Switch>
                  </div>
                </div>
                <div v-if="voiceProduct && formData.formSelectionOptions.toggleExpandCsgWaiver" class="flex items-center justify-between border-t border-gray-200 pt-6">
                  <div class="text-sm font-light h-64 overflow-hidden">
                    <CsgTerms :firstName="contactInformation.firstName"
                              :lastName="contactInformation.lastName"
                              :brand="brandService.getTitle()"
                              :phone="brandService.getSupportPhoneNumber()"
                              :address="brandService.getAddress()"
                    />
                  </div>
                </div>
                <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                  <div class="text-sm font-light pr-6">
                    I have read, understood and accept the <a :href="`https://uniti-bpo-website-assets.assets.unitiinternet.com/${brandService.getName()}/direct-debit-service-request-agreement.pdf`" target="_blank" class="text-blue-500 hover:text-blue-800 hover:underline transition duration-250">Direct Debit Service Request Agreement.</a>
                  </div>
                  <div class="text-base font-medium text-gray-900">
                    <Switch
                      @click="handleClickDdAgreement"
                      v-model="formData.formSelectionOptions.ddAgreementToggle"
                      :class="[
                        (formData.formSelectionOptions.ddAgreementToggle ? 'bg-brand-accent' : 'bg-gray-200'),
                        ('relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'),
                        (ddAgreementError ? 'ring-2 ring-offset-2 ring-red-600' : 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-accent')
                        ]"
                      >
                      <span class="sr-only">Use setting</span>
                      <span :class="[formData.formSelectionOptions.ddAgreementToggle ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                        <span :class="[formData.formSelectionOptions.ddAgreementToggle ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                          <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </span>
                        <span :class="[formData.formSelectionOptions.ddAgreementToggle ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                          <svg class="h-3 w-3 text-brand-accent" fill="currentColor" viewBox="0 0 12 12">
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                          </svg>
                        </span>
                      </span>
                    </Switch>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <h2 class="mt-9 text-lg font-medium text-gray-900">Promotions</h2>

          <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
            <h3 class="sr-only">Enter promotion code here</h3>
            <div class="min-w-0 flex-1 p-4">
              <label for="promoCode" class="block text-sm font-medium text-gray-700 pb-1">Enter promotion code</label>
              <input type="text" name="promoCode" id="promoCode" autocomplete="off" v-model="promoCode" @blur="v$.ddAccountBsb.$touch" class="uppercase border-gray-200 rounded-md shadow-sm focus:ring-brand-accent focus:border-brand-accent sm:text-sm" />
              <button @click="promoCodeClickHandler" type="button" class="inline-flex items-center ml-2 px-3.5 py-2.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-accent">Apply</button>
              <p v-if="isPromoValid" class="mt-1 font-normal text-sm text-green-500">Promo code {{ validPromotion?.code }} applied!</p>
              <p v-if="!isPromoValid && promoCode && promoCode.length > 0" class="mt-1 font-normal text-sm text-red-500">Promo code invalid!</p>
            </div>
          </div>

          <!-- Order summary -->
          <h2 class="mt-9 text-lg font-medium text-gray-900">Order summary</h2>

          <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
            <h3 class="sr-only">Items in your cart</h3>
            <ul role="list" class="divide-y divide-gray-200">
              <li v-for="product in products" :key="product.name" v-show="product.type === 'internet' || product.type === 'voice' || product.type === 'hardware'" class="flex py-6 px-4 sm:px-6">
                <div class="">
                  <div class="min-w-0 flex-1">
                      <h4 class="text-sm font-medium text-gray-700">
                          {{ product.name }}
                      </h4>
                      <p class="mt-1 text-sm text-gray-500">
                        {{ product.description }}
                      </p>
                      <p v-if="product.type !== 'hardware'" class="mt-1 text-sm text-gray-500">
                        Monthly
                      </p>
                    </div>
                </div>

                <div class="ml-6 flex-1 flex flex-col">
                  <div class="flex-1 flex flex-row-reverse items-start justify-between">
                    <p :class="['text-sm font-medium', isPromoValid && product.discounted ? 'text-decoration-line: line-through text-gray-300' : 'text-gray-900']">{{ utilityService.formatCurrency(product.price) }}</p>
                  </div>
                  <div class="flex-1 flex flex-row-reverse items-start">
                    <p v-if="isPromoValid && product.discounted" class="text-sm font-medium text-gray-900">{{ utilityService.formatCurrency(product.discountedPrice ?? 0) }}</p>
                  </div>
                </div>
              </li>
            </ul>
            <dl class="border-t border-gray-200 pb-6">
              <div v-for="product in products" :key="product.name" v-show="product.code === 'ACTIVATION-FEE'" class="flex items-center justify-between px-4 sm:px-6">
                <dt class="text-sm py-6">
                  Activation Fee
                </dt>
                <div class="ml-6 flex-1 flex flex-col">
                  <div class="flex-1 flex flex-row-reverse items-start justify-between">
                    <dd v-if="product.price >= 0 && !isPromoValid || !product.displayDiscount" :class="['text-sm font-medium', isPromoValid && product.displayDiscount ? 'text-decoration-line: line-through text-gray-300' : 'text-gray-900']">
                      {{ utilityService.formatCurrency(product.price) }}
                    </dd>
                  </div>
                  <div class="flex-1 flex flex-row-reverse items-start">
                    <dd v-if="isPromoValid && product.discounted" class="text-sm font-medium text-gray-900">
                      {{ utilityService.formatCurrency(product.discountedPrice ?? 0) }}
                    </dd>
                  </div>
                </div>
              </div>
              <div v-for="product in products" :key="product.name" v-show="product.code === 'NDC'" class="flex items-center justify-between border-t border-gray-200 pt-6 px-4 sm:px-6">
                <dt class="text-sm pb-4">
                  New Development Fee
                  <div class="tooltip">
                    <component :is=InformationCircleIcon class="h-6 w-6 text-brand-button-text" aria-hidden="true"/>
                    <span class="tooltiptext">
                      <p class="tooltipmsg">The New Development Charge or NDC is a charge that is passed on from the network wholesale provider to residents via the Retail Service Provider. This fee is applied to all new developments as a one-time fee for the first user to activate an internet service.</p>
                    </span>
                  </div>
                </dt>
                <div class="ml-6 flex-1 flex flex-col pb-4">
                  <div class="flex-1 flex flex-row-reverse items-start justify-between">
                    <dd :class="['text-sm font-medium', isPromoValid && product.displayDiscount ? 'text-decoration-line: line-through text-gray-300' : 'text-gray-900']">
                      {{ utilityService.formatCurrency(product.price) }}
                    </dd>
                  </div>
                  <div class="flex-1 flex flex-row-reverse items-start">
                    <dd v-if="isPromoValid && product.discounted" class="text-sm font-medium text-gray-900">
                      {{ utilityService.formatCurrency(product.discountedPrice ?? 0) }}
                    </dd>
                  </div>
                </div>
              </div>
              <div v-if="delayedNdc" class="flex items-center justify-between border-t border-gray-200 py-6 px-4 sm:px-6">
                <dt class="text-sm font-extralight">
                  The new development fee of $300 will be applied in month 13
                </dt>
              </div>
              <div v-if="yearOff" class="flex items-center justify-between border-t border-gray-200 py-6 px-4 sm:px-6">
                <dt class="text-sm font-extralight">
                  First Year Free! - Standard billing will commence in month 13
                </dt>
              </div>
              <div v-if="monthOff" class="flex items-center justify-between border-t border-gray-200 py-6 px-4 sm:px-6">
                <dt class="text-sm font-extralight">
                  First Month Free!
                </dt>
              </div>
              <div v-if="preInstalledRouter" class="flex items-center justify-between border-t border-gray-200 py-6 px-4 sm:px-6">
                <dt class="text-sm font-extralight">
                  Pre-Installed router
                </dt>
              </div>
              <div class="flex items-center justify-between border-t border-gray-200 py-6 px-4 sm:px-6">
                <dt class="text-base font-medium">
                  First Invoice Total
                </dt>
                <dd class="text-base font-medium text-gray-900">
                  {{ utilityService.formatCurrency(orderService.calculateFirstInvoiceTotal(products, isPromoValid, validPromotion)) }}
                </dd>
              </div>
              <div class="flex items-center justify-between px-4 sm:px-6">
                <dt class="text-sm font-extralight">
                  The total minimum cost of service over duration of the contract selected less any discounts is <span class="font-medium">{{ utilityService.formatCurrency(orderService.calculateMinimumCost(products, isPromoValid, validPromotion)) }}</span>
                </dt>
              </div>
            </dl>

            <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
              <div class="pb-4">
                <RecaptchaV2
                  @widget-id="handleWidgetId"
                  @error-callback="handleErrorCalback"
                  @expired-callback="handleExpiredCallback"
                  @load-callback="handleLoadCallback"
                />
              </div>
              <AsyncButton @onClick="handleConfirmOrder" class="w-full">Confirm Order</AsyncButton>

              <div>
                <p v-if="confirmAddressError || termsAgreementError || ddAgreementError || csgWaiverError || captchaError" class="mt-4 text-sm text-red-600">
                  Please confirm your details and tick the required fields
                </p>
                <p v-if="v$.$error" class="mt-4 text-sm text-red-600">
                  Please fill out all required form fields
                </p>
                <p v-for="error in errors" :key="error" class="mt-4 text-sm text-red-600">
                  {{ error }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang='ts'>
import { computed, reactive, defineEmits, defineProps, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, email, required, requiredIf, minLength, maxLength, integer } from '@vuelidate/validators';
import { ccValidations } from '@/validations/ccValidations';
import { nameValidations } from '@/validations/nameValidations';
import { OrderServiceInterface } from '@/services/orderServiceInterface';
import { injectStrict } from '@/plugins/vueServiceContainerPlugin';
import { Product } from '@/adapters/catalogAdapterInterface';
import { UtilityServiceInterface } from '@/services/utilityServiceInterface';
import AsyncButton from '../library/AsyncButton.vue';
import { BrandServiceInterface } from '@/services/brand/brandServiceInterface';
import format from 'date-fns/format';
import addDays from 'date-fns/fp/addDays';
import store from '@/store';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import { RecaptchaV2, useRecaptcha } from 'vue3-recaptcha-v2';

// Adding this prevents Vue warnings
import { Switch } from '@headlessui/vue';
import { Action, Promotion } from '@uniti-internet/promotions-js-client/dist/utilities/types';
import CsgTerms from '@/components/content/CsgTerms.vue';

export type ContactInformation = {
  firstName: string | null,
  lastName: string | null,
  phoneNumber: string | null,
  email: string
};

type Service = {
  id: string,
  address: string,
  carrier: string,
  locationId: string,
  requiresNewDevelopmentCost: boolean,
  development: string,
  suburb: string,
  state: string,
  rtcDays: number,
}

type ConfirmOrderData = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: number | null,
  companyName: string,
  abnNumber: number | null,
  ccNumber: number | null,
  ccName: string,
  ccExpiry: string,
  ccCvc: string,
  ddAccountNumber: string,
  ddAccountBsb: string,
  token?: string,
  activationDate: string,
  formSelectionOptions: {
    businessAccount: boolean,
    paymentMethod: string,
    confirmAddressToggle: boolean,
    termsAgreementToggle: boolean,
    csgWaiverToggle: boolean,
    toggleExpandCsgWaiver: boolean,
    ddAgreementToggle: boolean,
    pickActivationDate: boolean
  },
  captchaValid: boolean
}

const orderService = injectStrict<OrderServiceInterface>('OrderServiceInterface');
const utilityService = injectStrict<UtilityServiceInterface>('UtilityServiceInterface');
const brandService = injectStrict<BrandServiceInterface>('BrandServiceInterface');

const props = defineProps<{
  errors: string[],
  products: Product[],
  service: Service,
  isPromoValid: boolean,
  promotionDiscounts: Array<Action>,
  validPromotion?: Promotion,
  suggestedPromotion?: Promotion
}>();

const delayedNdc = computed(() => {
  return props.promotionDiscounts.find(discount => discount.actionCatalogObject.code === 'DELAYED-NDC');
});

const yearOff = computed(() => {
  return props.promotionDiscounts.find(discount => discount.actionCatalogObject.code === 'YEAR-OFF');
});

const monthOff = computed(() => {
  let plan = '';
  if (props.products.length > 0 && props.products.filter(pr => pr.type === 'internet').length > 0) {
    plan = props.products.filter(pr => pr.type === 'internet')[0].id;
  }
  return props.promotionDiscounts.find(discount => discount.actionCatalogObject.code === 'ONE-OFF' && (discount.applicableTo.length <= 0 || (discount.applicableTo.length > 0 && discount.applicableTo.includes(plan))));
});

const preInstalledRouter = computed(() => {
  return props.promotionDiscounts.find(discount => discount.actionCatalogObject.code === 'FREE-ROUTER-INSTALLED');
});

const account = computed(() => {
  return store.state.account;
});

const salesPerson = computed(() => {
  return store.state.salesPerson;
});

const isPromoValid = computed(() => {
  return props.isPromoValid;
});

const voiceProduct = computed(() => {
  return props.products.filter(product => product.type === 'voice').length > 0;
});

const today = computed((): string => {
  return format(new Date(), 'yyyy-MM-dd');
});

const tomorrow = computed((): string => {
  return format(addDays(1, new Date()), 'yyyy-MM-dd');
});

const formData : ConfirmOrderData = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: null,
  companyName: '',
  abnNumber: null,
  ccNumber: null,
  ccName: '',
  ccExpiry: '',
  ccCvc: '',
  ddAccountNumber: '',
  ddAccountBsb: '',
  token: '',
  crmId: null,
  activationDate: today.value,
  formSelectionOptions: {
    businessAccount: false,
    paymentMethod: 'cc',
    confirmAddressToggle: false,
    termsAgreementToggle: false,
    csgWaiverToggle: false,
    toggleExpandCsgWaiver: false,
    ddAgreementToggle: false,
    pickActivationDate: false
  },
  captchaValid: false
});

const { handleReset } = useRecaptcha();

const handleWidgetId = (widgetId: number) => {
  handleReset(widgetId);
};
const handleErrorCalback = () => {
  formData.captchaValid = false;
};
const handleExpiredCallback = () => {
  formData.captchaValid = false;
};
const handleLoadCallback = (response: unknown) => {
  if (response) {
    formData.captchaValid = true;
  }
};

const promoCode = ref(props.suggestedPromotion?.code);

function promoCodeClickHandler () {
  let promoCodeUppercase;
  if (promoCode.value) {
    promoCodeUppercase = promoCode.value.toUpperCase();
  }
  emit('onEnterPromoCode', promoCodeUppercase);
}

const contactInformation : ContactInformation = reactive({
  firstName: null,
  lastName: null,
  phoneNumber: null,
  email: ''
});

const pickActivationDate = ref(() => formData.formSelectionOptions.pickActivationDate);
const businessAccount = ref(() => formData.formSelectionOptions.businessAccount);
const paymentMethodRequiredCc = ref(() => formData.formSelectionOptions.paymentMethod === 'cc' && (!salesPerson.value));
const paymentMethodRequiredDd = ref(() => formData.formSelectionOptions.paymentMethod === 'dd' && (!salesPerson.value));

const rules = {
  firstName: {
    required: helpers.withMessage('Please enter a firstname', required),
    nameValidation: helpers.withMessage('Please enter letters only', nameValidations.nameValidation),
    maxLengthValue: helpers.withMessage('Please enter less than 20 chars', maxLength(20))
  },
  lastName: {
    required: helpers.withMessage('Please enter a lastname', required),
    nameValidation: helpers.withMessage('Please enter letters only', nameValidations.nameValidation),
    maxLengthValue: helpers.withMessage('Please enter less than 20 chars', maxLength(20))
  },
  email: {
    required: helpers.withMessage('Please enter an email address', required),
    email: helpers.withMessage('Please enter a valid email address', email)
  },
  phoneNumber: {
    required: helpers.withMessage('This field cannot be empty', required),
    integer: helpers.withMessage('Please enter numbers only', integer),
    minLength: helpers.withMessage('Please enter a phone number with 10 digits', minLength(10)),
    maxLengthValue: helpers.withMessage('Please enter a phone number with 10 digits', maxLength(10))
  },
  companyName: {
    requiredIf: helpers.withMessage('Please enter a company name', requiredIf(businessAccount.value)),
    minLength: helpers.withMessage('Please enter a phone number with 10 digits', minLength(1)),
    maxLengthValue: helpers.withMessage('Please enter less than 50 chars', maxLength(50)),
    nameValidation: helpers.withMessage('Please enter letters only', nameValidations.nameValidation)
  },
  abnNumber: {
    requiredIf: helpers.withMessage('Please enter an ABN number', requiredIf(businessAccount.value)),
    integer: helpers.withMessage('Please enter numbers only', integer),
    minLength: helpers.withMessage('Please enter a 11 digit ABN number', minLength(11)),
    maxLength: helpers.withMessage('Please enter a 11 digit ABN number', maxLength(11))
  },
  ccNumber: {
    requiredIf: helpers.withMessage('Please enter a credit card number', requiredIf(paymentMethodRequiredCc.value)),
    integer: helpers.withMessage('Please enter numbers only', integer),
    ccValidation: helpers.withMessage('Please enter a valid credit card number', ccValidations.numberValidation)
  },
  ccName: {
    requiredIf: helpers.withMessage('Please enter a credit card number', requiredIf(paymentMethodRequiredCc.value)),
    maxLengthValue: helpers.withMessage('Please enter less than 20 chars', maxLength(40))
  },
  ccExpiry: {
    requiredIf: helpers.withMessage('Please enter a credit card number', requiredIf(paymentMethodRequiredCc.value)),
    ccExpiryValidation: helpers.withMessage('Please enter a valid MM/YY expiry', ccValidations.expiryValidation)
  },
  ccCvc: {
    requiredIf: helpers.withMessage('Please enter a credit card number', requiredIf(paymentMethodRequiredCc.value)),
    ccCvcValidation: helpers.withMessage('Please enter a valid CVC number', ccValidations.cvcValidation)
  },
  ddAccountNumber: {
    requiredIf: helpers.withMessage('Please enter an account number', requiredIf(paymentMethodRequiredDd.value)),
    integer: helpers.withMessage('Please enter numbers only', integer),
    minLength: helpers.withMessage('Please enter a valid account number', minLength(6)),
    maxLength: helpers.withMessage('Please enter a valid account number', maxLength(10))
  },
  ddAccountBsb: {
    requiredIf: helpers.withMessage('Please enter a BSB number', requiredIf(paymentMethodRequiredDd.value)),
    integer: helpers.withMessage('Please enter numbers only', integer),
    minLength: helpers.withMessage('Please enter a 6 digit BSB number', minLength(6)),
    maxLength: helpers.withMessage('Please enter a 6 digit BSB number', maxLength(6))
  },
  activationDate: {
    requiredIf: helpers.withMessage('Please select a date the service should be activated', requiredIf(pickActivationDate.value))
  }
};
const v$ = useVuelidate(rules, formData);

const directDebitFormFields = computed(() => {
  return formData.formSelectionOptions.paymentMethod !== 'cc';
});

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (e: 'onEnterPromoCode', promoCodeInput?: string): void,
  (e: 'onConfirmOrder', formData: ConfirmOrderData, done: () => void): void,
  (e: 'onContactInformation', contactInformation: ContactInformation): void,
}>();

const confirmAddressError = ref(false);
const termsAgreementError = ref(false);
const ddAgreementError = ref(false);
const csgWaiverError = ref(false);
const captchaError = ref(false);

const handleConfirmOrder = async (done: () => void) => {
  const formValidated = await v$.value.$validate();

  if (!formData.formSelectionOptions.confirmAddressToggle) confirmAddressError.value = true;
  if (!formData.formSelectionOptions.termsAgreementToggle) termsAgreementError.value = true;
  if (!formData.formSelectionOptions.ddAgreementToggle) ddAgreementError.value = true;
  if (voiceProduct.value && !formData.formSelectionOptions.csgWaiverToggle) csgWaiverError.value = true;
  if (!formData.captchaValid) captchaError.value = true;

  if (!voiceProduct.value) {
    if (formValidated && !confirmAddressError.value && !termsAgreementError.value && !ddAgreementError.value && !captchaError.value) {
      emit('onConfirmOrder', formData, done);
    } else {
      done();
    }
  } else if (voiceProduct.value) {
    if (formValidated && voiceProduct.value && !confirmAddressError.value && !termsAgreementError.value && !ddAgreementError.value && !csgWaiverError.value && !captchaError.value) {
      emit('onConfirmOrder', formData, done);
    } else {
      done();
    }
  }
};

const handleClickConfirmAddress = () => {
  if (formData.formSelectionOptions.confirmAddressToggle) confirmAddressError.value = false;
};

const handleClickTermsAgreement = () => {
  if (formData.formSelectionOptions.termsAgreementToggle) termsAgreementError.value = false;
};

const handleClickCsgWaiver = () => {
  if (formData.formSelectionOptions.csgWaiverToggle) csgWaiverError.value = false;
};

const handleClickDdAgreement = () => {
  if (formData.formSelectionOptions.ddAgreementToggle) ddAgreementError.value = false;
};

const handleClickBusinessAccount = () => {
  formData.companyName = '';
  formData.abnNumber = null;
};

const handleActivationDate = () => {
  if (formData.formSelectionOptions.pickActivationDate) {
    formData.activationDate = tomorrow.value;
  } else {
    formData.activationDate = today.value;
  }
};

const handleFirstNameBlur = () => {
  v$.value.firstName.$touch();

  if (!v$.value.firstName.$error) {
    contactInformation.firstName = formData.firstName;
  } else {
    contactInformation.firstName = null;
  }
};

const handleLastNameBlur = () => {
  v$.value.lastName.$touch();

  if (!v$.value.lastName.$error) {
    contactInformation.lastName = formData.lastName;
  } else {
    contactInformation.lastName = null;
  }
};

const handlePhoneBlur = () => {
  v$.value.phoneNumber.$touch();

  if (!v$.value.phoneNumber.$error) {
    contactInformation.phoneNumber = formData.phoneNumber;
  } else {
    contactInformation.phoneNumber = null;
  }
};

const handleEmailBlur = () => {
  v$.value.email.$touch();

  if (!v$.value.email.$error) {
    contactInformation.email = formData.email;
  } else {
    contactInformation.email = null;
  }
};

const toggleExpandCsgWaiver = () => {
  formData.formSelectionOptions.toggleExpandCsgWaiver = !formData.formSelectionOptions.toggleExpandCsgWaiver;
};

watch(
  contactInformation,
  (newContactInformation) => {
    if (newContactInformation.email) {
      emit('onContactInformation', newContactInformation);
    }
  }
);

</script>
<style scoped>
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  top: 7px;
  left: 7px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 30px;
  left: -5px;
}

.tooltip .tooltiptext .tooltipmsg {
  margin: 15px 15px 15px 15px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -138px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip .tooltiptext {
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
